<template>
  <div class="modal">
    <v-row>
      <v-col class="col-6">
        <v-text-field
          clearable
          @keyup="lookUpJobNoDebounced"
          label="Search for a job no"
          v-model="searchTerm"></v-text-field>
      </v-col>
      <v-col class="col-6 text-right"><v-btn @click="$emit('close')" small>Close</v-btn></v-col>
    </v-row>
    <v-row dense v-if="foundJobNos.length > 0">
      <v-col class="col-12">
        <v-list>
          <v-list-item
            @click="populateJobNo(index)"
            v-for="(no, index) in foundJobNos" :key="`s_${index}`">
              {{ no.clone_id }}-{{ no.clone_count }}-{{ no.quoted_by_initials }}
              &nbsp;&nbsp;
              <v-btn x-small>Select Job No</v-btn>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'PoAddJobNo',
  data() {
    return {
      foundJobNos: [],
      searchTerm: null,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    lookUpJobNoDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.lookUpJobNo();
      }, 500);
    },
    lookUpJobNo() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 2) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/quotations/lookUpJobNo.json?token=${this.token}`, postData)
            .then((response) => {
              this.foundJobNos = response.data;
            });
        }
      }
    },
    populateJobNo(jobNoIndex) {
      const number = this.foundJobNos[jobNoIndex].id;
      const postData = {
        'quotation_id': number,
        'po_id': this.$route.params.id,
      };
      axios.post(`/purchaseOrders/addJobNo.json?token=${this.token}`, postData)
        .then(() => {
          this.$emit('populateJobNo', number);
        });
    },
  },
  mounted() {},
}
</script>
